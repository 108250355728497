
import ApiService from './ApiService'
import LocalStorageService from './LocalStorageService'
import { capitalize } from '../utils/text'

class OrganisationTagService {
  set = async (apiToken, params, data) => {
    var url = '/api/organisationtag'

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationTagService:set ', result)
    return result
  }

  get = async (apiToken, params, where) => {
    var url = '/api/organisationtag'

    if (where) {
      params.where = where
    }

    params.limit = 1000

    try {
      var organisationTags = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationTagService:get ', organisationTags)
    return organisationTags?.data
  }

  getAcademicYears = async (apiToken, activeOrg) => {
    const params = {
      fields: 'key,value',
      limit: 1000
    }

    const where = {
      organisation: activeOrg.id,
      type: 'besmart'
    }

    const terms = LocalStorageService.get('terms')

    const organisationTags = await this.get(apiToken, params, where)
    if (organisationTags?.error) {
      // return {error: organisationTags}
    } else if (organisationTags?.length) {
      const academicYears = organisationTags.find(tag => tag.key === 'enabledAcademicYears')
      try {
        const parsedAcademicYears = JSON.parse(academicYears.value)
        parsedAcademicYears.push('Former')

        return parsedAcademicYears
      } catch (err) {
        console.error(err)
      }
    }
  }
}
export default new OrganisationTagService()
